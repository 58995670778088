import React from "react";
import { TfiHeadphoneAlt } from "react-icons/tfi";
import { HiOutlineBriefcase } from "react-icons/hi2";
import { PiNewspaperLight } from "react-icons/pi";
import { Fade } from "react-reveal";

const PreferToSay = () => {
	return (
		<>
			<div className="container py-[68px] px-[20px] md:py-[98px] md:px-[24px] lg:py-[118px] lg:px-0  border border-x-0 border-t-0 dark:border-gray-200">
				<Fade ssrReveal >
					<div className="w-full max-w-[32rem] mx-auto text-jacarta-900 dark:text-white text-center">
						<h2 className="text-center text-[24px] md:text-[32px] lg:text-[38px] font-medium ">Prefer to reach out directly?
						</h2>
						<p>
							{/* Lorem ipsum dolor sit amet, consectetur adipiscing elit laoreet bibendum in nunc duis eros tristique pulvinar. */}
							Contact us or engage with our 24/7 customer service chatbot, NetZylo AI, for assistance anytime.
						</p>

					</div>
					<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8  py-10 rounded-sm bg-white dark:bg-jacarta-900">
						<div className="px-[24px] py-[50px] lg:px-[24px] lg:py-[97px] dark:border-jacarta-600 border-white mb-5 overflow-hidden rounded-lg border bg-gradient-to-tr from-hotPink via-purplish to-accent">
							<TfiHeadphoneAlt className="h-8 w-8 mb-6 text-white" />
							<p className="text-[28px] text-white mb-6 font-medium" >Help & Support
							</p>
							<p className="text-md text-white mb-6">
								Need assistance or have questions? Our dedicated support team is here to help. Reach out anytime at
							</p>
							<a href="mailto:support@netzylo.io" className="text-md text-white ">support@netzylo.com</a>
						</div>
						<div className="px-[24px] py-[50px] lg:px-[24px] lg:py-[97px] dark:border-jacarta-600 border-jacarta-900 mb-5 overflow-hidden rounded-lg border ">
							<HiOutlineBriefcase className="h-8 w-8 mb-6 text-jacarta-800 dark:text-white" />
							<p className="text-[28px] dark:text-white text-jacarta-900 mb-6 font-medium" >Sales
							</p>
							<p className="text-md dark:text-white text-jacarta-900 mb-6">
								Looking to explore our products or discuss tailored solutions for your needs? Connect with our sales team at
							</p>
							<a href="mailto:sales@netzylo.io" className="text-md dark:text-white text-jacarta-900 "> sales@netzylo.com</a>
						</div>
						<div className="px-[24px] py-[50px] lg:px-[24px] lg:py-[97px] dark:border-jacarta-600 border-jacarta-900 mb-5 overflow-hidden rounded-lg border ">
							<PiNewspaperLight className="h-8 w-8 mb-6 text-jacarta-800 dark:text-white" />
							<p className="text-[28px] dark:text-white text-jacarta-900 mb-6 font-medium" >Press
							</p>
							<p className="text-md dark:text-white text-jacarta-900 mb-6">
								Stay updated with the latest news and developments. Subscribe to our newsletter for insightful updates by emailing
							</p>
							<a href="mailto:ir@netzylo.io" className="text-md dark:text-white text-jacarta-900 "> ir@netzylo.com</a>
						</div>
					</div>
				</Fade>
			</div>
		</>
	)
}


export default PreferToSay