import React from "react";
import { Fade } from "react-reveal";
import QAccordion from "./accoedion"
import Link from "next/link";
const Questions = () => {
    return (
        <>
            <div className="relative container py-[68px] px-[20px] md:py-[98px] md:px-[24px] lg:py-[118px] lg:px-0  border border-x-0 border-t-0 dark:border-gray-200">
                <Fade ssrReveal >


                    <div className="grid lg:grid-cols-3 gap-10">
                        <div className="col-span-1 ">
                            <div className="">
                                <h1 className="text-[24px] md:text-[32px] lg:text-[38px] text-center lg:text-start font-medium mb-[16px]">Frequently asked questions</h1>
                                <p className="text-[16px] md:text-[18px] mb-[40px] text-center lg:text-start ">Discover Web3 Domains: FAQs on benefits, ownership, and more.</p>
                            </div>
                        </div>

                        <div className="lg:col-span-2">
                            <QAccordion />
                        </div>


                    </div>
                    {/* <div>
                        <Link href="/notified" className="items-center  justify-center rounded-md text-[18px] font-medium  bg-gradient-to-tr from-hotPink via-purplish to-accent py-[22px] px-[32px] md:py-[24px] md:px-[38px] cursor-pointer button z-[2] transition-all duration-500 text-white hover:-translate-y-1 text-center">Notified</Link>

                    </div> */}
                </Fade>
            </div >
        </>
    )
}
export default Questions;