import Image from 'next/image';
import React from 'react';
import { Fade } from 'react-reveal';

function MajorFeatures() {
	return (
		<section className='container py-[68px] px-[20px] md:py-[98px] md:px-[24px] lg:py-[118px] lg:px-0   border border-x-0 border-t-0 dark:border-gray-200'>
			<div >
				<div className=" dark:bg-jacarta-900  text-center  ">
					<div className="grid grid-cols-1 gap-8 md:gap-10 lg:grid-cols-3">
						<Fade ssrReveal bottom cascade delay={350}>
							<div className='w-full lg:w-[100%] md:px-20 lg:px-2' >
								<Image width={500} height={100} className=" mx-auto z-[-1]"  alt="Readable-Wallets" src="/images/features/f-3.svg" />
								<div className='px-0'>
									<h2 className="mb-5 font-medium text-[28px]">
										Readable Wallets
									</h2>
									<p className="text-[18px] text-jacarta-900 dark:text-jacarta-400">
										Web3 domains seamlessly link to crypto wallets, making sending and receiving digital assets as simple as sending a message.
									</p>
								</div>
							</div>
							<div className='w-full lg:w-[100%] md:px-20 lg:px-2' >
								<Image width={500} height={100} className=" mx-auto  z-[-1]" alt='Blockchain-Domain' src="/images/features/f-2.svg" />
								<div className='px-0'>
									<h2 className="mb-5 font-medium text-[28px]">
									Blockchain Domain
									</h2>
									<p className="text-[18px] text-jacarta-900 dark:text-jacarta-400">
										Utilize web3 domains as your universal username across decentralized apps, simplifying access and enhancing your digital presence.
									</p>
								</div>
							</div>
							<div className='w-full lg:w-[100%] md:px-20 lg:px-2' >
								<Image width={500} height={100} className=" mx-auto  z-[-1]" alt='Web3-Identity' src="/images/features/f-1.svg" />
								<div className='px-0'>
									<h2 className="mb-5 font-medium text-[28px]">
									Web3 Identity
									</h2>
									<p className="text-[18px] text-jacarta-900 dark:text-jacarta-400">
										Harness the power of Web3 domains to create dynamic, decentralized websites that reflect your unique identity.
									</p>
								</div>
							</div>
						</Fade>
					</div>
				</div>
			</div >
		</section>
	)
}

export default MajorFeatures