import { useTheme } from "next-themes";

import Image from "next/image";
import React from "react";
const Eco = () => {
  const partnerItem = [

    {
      img: 'infura',
      img_dark: 'infura-dark',
      alt: 'infura-logo'
    },
    {
      img: 'tangem',
      img_dark: 'tangem-dark',
      alt: 'tangem-logo'
    },
    {
      img: 'openai',
      img_dark: 'openai-dark',
      alt: 'openai-logo'
    },

    // {
    //   img: 'pinata',
    //   img_dark: 'pinata-dark',
    //   alt: 'pinata-logo'
    // },
    {
      img: 'metamask',
      img_dark: 'metamask-dark',
      alt: 'metamask-logo'
    },
    // {
    //   img: 'ipfs',
    //   img_dark: 'ipfs-dark',
    //   alt: 'ipfs-logo'
    // },
    {
      img: 'polygon',
      img_dark: 'polygon-dark',
      alt: 'polygon-logo'
    },
    {
      img: 'thirdWeb',
      img_dark: 'thirdWeb-dark',
      alt: 'thirdweb-logo'
    },

    {
      img: 'infura',
      img_dark: 'infura-dark',
      alt: 'infura-logo'
    },
    {
      img: 'tangem',
      img_dark: 'tangem-dark',
      alt: 'tangem-logo'
    },
    {
      img: 'openai',
      img_dark: 'openai-dark',
      alt: 'openai-logo'
    },
    {
      img: 'tangem',
      img_dark: 'tangem-dark',
      alt: 'tangem-logo'
    },
    // {
    //   img: 'pinata',
    //   img_dark: 'pinata-dark',
    //   alt: 'pinata-logo'
    // },
    {
      img: 'metamask',
      img_dark: 'metamask-dark',
      alt: 'metamask-logo'
    },
    // {
    //   img: 'ipfs',
    //   img_dark: 'ipfs-dark',
    //   alt: 'ipfs-logo'
    // },
    {
      img: 'polygon',
      img_dark: 'polygon-dark',
      alt: 'polygon-logo'
    },
    {
      img: 'thirdWeb',
      img_dark: 'thirdWeb-dark',
      alt: 'thirdweb-logo'
    },

    {
      img: 'infura',
      img_dark: 'infura-dark',
      alt: 'infura-logo'
    },
    {
      img: 'tangem',
      img_dark: 'tangem-dark',
      alt: 'tangem-logo'
    },
    {
      img: 'openai',
      img_dark: 'openai-dark',
      alt: 'openai-logo'
    },
    {
      img: 'tangem',
      img_dark: 'tangem-dark',
      alt: 'tangem-logo'
    },
    // {
    //   img: 'pinata',
    //   img_dark: 'pinata-dark',
    //   alt: 'pinata-logo'
    // },
    {
      img: 'metamask',
      img_dark: 'metamask-dark',
      alt: 'metamask-logo'
    },
    // {
    //   img: 'ipfs',
    //   img_dark: 'ipfs-dark',
    //   alt: 'ipfs-logo'
    // },
    {
      img: 'polygon',
      img_dark: 'polygon-dark',
      alt: 'polygon-logo'
    },
    {
      img: 'thirdWeb',
      img_dark: 'thirdWeb-dark',
      alt: 'thirdweb-logo'
    },


  ];

  const partnerItem2 = [

    {

      img: 'infura-dark',
      alt: 'infura-logo'
    },
    {

      img: 'tangem-dark',
      alt: 'tangem-logo'
    },
    {

      img: 'openai-dark',
      alt: 'openai-logo'
    },
    // {

    //   img: 'pinata-dark',
    //   alt: 'pinata-logo'
    // },
    {

      img: 'metamask-dark',
      alt: 'metamask-logo'
    },
    // {

    //   img: 'ipfs-dark',
    //   alt: 'ipfs-logo'
    // },
    {

      img: 'polygon-dark',
      alt: 'polygon-logo'
    },
    {

      img: 'thirdWeb-dark',
      alt: 'thirdweb-logo'
    },

    {

      img: 'infura-dark',
      alt: 'infura-logo'
    },
    {

      img: 'tangem-dark',
      alt: 'tangem-logo'
    },
    {

      img: 'openai-dark',
      alt: 'openai-logo'
    },
    // {

    //   img: 'pinata-dark',
    //   alt: 'pinata-logo'
    // },
    {

      img: 'metamask-dark',
      alt: 'metamask-logo'
    },
    // {

    //   img: 'ipfs-dark',
    //   alt: 'ipfs-logo'
    // },
    {

      img: 'polygon-dark',
      alt: 'polygon-logo'
    },
    {

      img: 'thirdWeb-dark',
      alt: 'thirdweb-logo'
    },

    {

      img: 'infura-dark',
      alt: 'infura-logo'
    },
    {

      img: 'tangem-dark',
      alt: 'tangem-logo'
    },
    {
      img: 'openai-dark',

      alt: 'openai-logo'
    },
    // {

    //   img: 'pinata-dark',
    //   alt: 'pinata-logo'
    // },
    {

      img: 'metamask-dark',
      alt: 'metamask-logo'
    },
    // {

    //   img: 'ipfs-dark',
    //   alt: 'ipfs-logo'
    // },
    {

      img: 'polygon-dark',
      alt: 'polygon-logo'
    },
    {

      img: 'thirdWeb-dark',
      alt: 'thirdweb-logo'
    },


  ];
  const { theme } = useTheme();
  const [items, setItems] = React.useState(partnerItem)

  React.useEffect(() => {
    if (theme === 'dark' || theme === 'system') {
      setItems(partnerItem2)
    } else {
      setItems(partnerItem)
    }
  }, [theme])

  return (

    <>


      {items.map((item, i) => (
        <div
          className="flex flex-shrink-0 items-center justify-center rounded-2.5xl md:px-6  "
          key={i}
        >
          <div className=" w-[90px] h-[100px] md:h-[120px] lg:h-[150px] md:w-[100%] flex items-center justify-center ">
            <Image
              width={200}
              height={150}
              className="object-cover rounded-[10px] "
              // src={`/images/eco/${theme === 'dark' ? item : lightMode[i]}.png`}
              src={`/images/eco/${item.img}.png`}

              alt={item.alt}

            />
          </div>
        </div>
      ))
      }

    </>

  );
};

export default Eco;
