import React from "react";
import { FaStar } from 'react-icons/fa'
import { Fade } from "react-reveal";
const Premium = () => {
    return (
        <>
            <div className="container py-[68px] px-[20px] md:py-[98px] md:px-[24px] lg:py-[118px] lg:px-0 border border-x-0 border-t-0 dark:border-gray-200">
                <Fade ssrReveal bottom delay={350}>

                    <div>
                        <p className="text-center text-[24px] md:text-[32px] lg:text-[38px] font-medium mb-[60px] lg:mb-[80px]"> Premium Domains</p>
                    </div>
                    <div className="gradient-box">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 p-10 rounded-sm bg-white dark:bg-jacarta-900">
                            <div className="bg-gray-300  dark:bg-gray-200 rounded-md p-4 hover:bg-gradient-to-tr hover:from-hotPink hover:via-purplish hover:to-accent transition-all duration-500 hover:-translate-y-1 cursor-pointer">
                                <div className="flex gap-2 items-center">
                                    {/* <FaStar className="w-3 h-3  " /> */}

                                    <FaStar style={{ fill: "#02f6d1" }} />
                                    <p className="text-[14px]">Premium</p>
                                </div>
                                <div className="p-4 mt-4 text-center">
                                    <p className="text-2xl lg:text-3xl font-bold pb-2">.cryptobull</p>
                                    <p className="pb-1">SLDs starting at:</p>
                                    <p className="text-[24px] font-bold">$ 10.00</p>
                                </div>
                            </div>
                            <div className="bg-gray-300  dark:bg-gray-200 rounded-md p-4 hover:bg-gradient-to-tr hover:from-hotPink hover:via-purplish hover:to-accent transition-all duration-500 hover:-translate-y-1 cursor-pointer">
                                <div className="flex gap-2 items-center">

                                    <FaStar style={{ fill: "#02f6d1" }} />
                                    <p className="text-[14px]">Premium</p>
                                </div>
                                <div className="p-4 mt-4 text-center">
                                    <p className="text-2xl lg:text-3xl font-bold pb-2">.rarenft</p>
                                    <p className="pb-1">SLDs starting at:</p>
                                    <p className="text-[24px] font-bold">$ 10.00</p>
                                </div>
                            </div>
                            <div className="bg-gray-300  dark:bg-gray-200 rounded-md p-4 hover:bg-gradient-to-tr hover:from-hotPink hover:via-purplish hover:to-accent transition-all duration-500 hover:-translate-y-1 cursor-pointer">
                                <div className="flex gap-2 items-center">

                                    <FaStar style={{ fill: "#02f6d1" }} />
                                    <p className="text-[14px]">Premium</p>
                                </div>
                                <div className="p-4 mt-4 text-center">
                                    <p className="text-2xl lg:text-3xl font-bold pb-2">.gamerpro</p>
                                    <p className="pb-1">SLDs starting at:</p>
                                    <p className="text-[24px] font-bold">$ 10.00</p>
                                </div>
                            </div>
                            <div className="bg-gray-300 dark:bg-gray-200 rounded-md p-4 hover:bg-gradient-to-tr hover:from-hotPink hover:via-purplish hover:to-accent transition-all duration-500 hover:-translate-y-1 cursor-pointer">
                                <div className="flex gap-2 items-center">

                                    <FaStar style={{ fill: "#02f6d1" }} />
                                    <p className="text-[14px]">Premium</p>
                                </div>
                                <div className="p-4 mt-4 text-center">
                                    <p className="text-2xl lg:text-3xl font-bold pb-2">.bitwallet</p>
                                    <p className="pb-1">SLDs starting at:</p>
                                    <p className="text-[24px] font-bold">$ 10.00</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
                <svg width="1em" height="1em">
                    <defs>
                        <linearGradient id={"blue-gradient"} x1="1" x2="0" y1="0" y2="1">
                            <stop offset="0%" stop-color=" #02f6d1 " />
                            <stop offset="50%" stop-color="#804AE4" />

                            <stop offset="100%" stop-color="#FF69B4" />
                        </linearGradient>

                    </defs>
                </svg>

            </div>
        </>
    )
}

export default Premium;