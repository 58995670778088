import React from "react";
import { useTheme } from "next-themes";
import Image from "next/image";
import { Fade } from "react-reveal";
import Link from "next/link";
const Invest = () => {
    const { theme } = useTheme();

    return (
        <section className="container relative py-[68px] px-[20px] md:py-[98px] md:px-[24px] lg:py-[118px] lg:px-0 ">
            <Fade ssrReveal delay={350}>
                <div className="  relative rounded-md px-[24px] md:px-[40px] lg:px-[100px] py-[80px] md:py-[140p] lg:py-[130px] text-center">
                    <div className="absolute  top-0 left-0 h-full w-full rounded-md bg-gradient-to-tr from-hotPink via-purplish to-accent "></div>

                    <div className="md:max-w-[600px] lg:max-w-[700px] mx-auto ">
                        <Fade ssrReveal bottom delay={300}>


                            <h2 className="mb-6 text-center font-display text-[30px] md:text-[44px] lg:text-[64px] font-normal text-white ">
                                Decentralize everything, everywhere
                            </h2>

                            <p className="text-[16px] md:text-[18px] mb-12 text-white">
                                Users purchasing subdomains will have their domains minted on Polygon Blockchain and transferred to their cryptocurrency wallet.
                            </p>

                            <div className="flex flex-col md:flex-row gap-6 md:gap-10 justify-center">

                                <a href="#contact" className="items-center justify-center text-[16px] font-bold md:text-[18px] py-[22px] px-[32px] md:py-[24px] md:px-[38px] button rounded-md  bg-white border border-jacarta-100 z-[2] transition-all duration-500 hover:-translate-y-1"
                                >
                                    <span className="bg-gradient-to-tr from-hotPink via-purplish to-accent  bg-clip-text text-transparent ">Search now</span>
                                </a>
                                <a href="#contact" className="items-center justify-center rounded-md border border-gray-100 dark:bg-jacarta-800 text-[16px] font-bold md:text-[18px] py-[22px] px-[32px] md:py-[24px] md:px-[38px] z-[2] transition-all duration-500 hover:-translate-y-1 bg-gray-300 text-white hover:bg-jacarta-base cursor-pointer dark:hover:bg-white dark:hover:text-gray-300"

                                >Learn more</a>
                            </div>
                        </Fade>
                    </div>

                </div>
            </Fade>
            <div className={` w-full absolute top-[30%] left-0  z-[10]  mix-blend-color-dodge`} >
                <div className="relative  h-[300px] w-[1200px] animate-fade  ">
                    <Image
                        fill
                        sizes="100vw"
                        src="/images/hero2.webp"
                        alt="dots-background"
                        className="object-cover "
                    />
                </div>

            </div>
        </section >
    )
}


export default Invest;
