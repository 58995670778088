import React, { useCallback, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import Image from "next/image";
import { MdArrowBack, MdArrowForward } from "react-icons/md";
import { Fade } from "react-reveal";
import { useTheme } from "next-themes";

const Carousal = () => {

    const currency = [
        {
            img: "bitcoin",
            name: "Bitcoin",
            alt:'Bitcoin-logo',
            text: 'Empowering individuals with decentralized and borderless financial possibilities.'
        },
        {
            img: "etherum",
            name: "Ethereum",
            alt:'Ethereum-logo',
            text: 'Revolutionizing smart contracts and decentralized applications through its powerful blockchain platform.'
        },
        {
            img: "litecoin",
            name: "Litecoin",
            alt:'Litecoin-logo',
            text: "The silver to Bitcoin's gold, offering fast and low-cost transactions."
        },
        {
            img: "matic",
            name: "Matic",
            alt:'Matic-logo',
            text: "Fueling the seamless and scalable blockchain ecosystem of tomorrow."
        },
        {
            img: "solana",
            name: "Solana",
            alt:'Solana-logo',
            text: ' Powering high-performance decentralized applications with lightning-fast transaction speeds.'
        },
        {
            img: "ripple",
            name: "Ripple",
            alt:'Ripple-logo',
            text: ' Transforming cross-border payments through its efficient and secure blockchain technology.'
        },
    ]


    const sliderRef = useRef(null);

    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }, []);



    const [isAtBeginning, setIsAtBeginning] = useState(true);
    const [isAtEnd, setIsAtEnd] = useState(false);

    const handleSlideChange = () => {
        if (sliderRef.current) {
            const swiper = sliderRef.current.swiper;
            setIsAtBeginning(swiper.isBeginning);
            setIsAtEnd(swiper.isEnd);
        }
    };

    const { theme } = useTheme();
    return <>
        <div className="flex flex-col md:flex-row gap-6 mb-[50px] md:justify-between">
            <Fade ssrReveal delay={300}>
                <div className="lg:max-w-[600px] text-center lg:text-start">
                    <h2 className="text-[24px] md:text-[32px] lg:text-[38px] font-medium text-center md:text-start ">
                        We support different cryptos and tokens
                    </h2>
                </div>
            </Fade>
            <Fade ssrReveal delay={300}>
                <div className="hidden md:flex justify-center lg:justify-end items-end gap-6 ">
                    <div onClick={handlePrev} className={!isAtBeginning ? "p-4 rounded-md bg-gradient-to-tr from-hotPink via-purplish to-accent  button cursor-pointer z-[2] transition-all duration-500 hover:-translate-y-1 disabled:bg-white text-white"
                        :
                        "dark:bg-jacarta-800 rounded-md text-white p-4 opacity-50 cursor-not-allowed z-[2] transition-all duration-500 hover:-translate-y-1 disabled:bg-white "}

                        disabled={isAtBeginning}
                    >
                        <MdArrowBack className={!isAtBeginning ? "w-[30px] h-[30px] text-white" : "w-[30px] h-[30px] dark:text-white text-jacarta-800"} />
                    </div>
                    <div onClick={handleNext} className={!isAtEnd ? "p-4 rounded-md bg-gradient-to-tr from-hotPink via-purplish to-accent  button cursor-pointer z-[2] transition-all duration-500 hover:-translate-y-1 disabled:bg-white text-white"
                        :
                        "dark:bg-jacarta-800 text-white p-4 opacity-50 rounded-md cursor-not-allowed  z-[2] transition-all duration-500 hover:-translate-y-1 disabled:bg-white"}

                        disabled={isAtEnd}
                    >
                        <MdArrowForward className={!isAtEnd ? "w-[30px] h-[30px] text-white" : "w-[30px] h-[30px] dark:text-white text-jacarta-800"} />
                    </div>
                </div>
            </Fade>
        </div>
        <Fade ssrReveal delay={300} bottom cascade>
            <Swiper
                ref={sliderRef}
                onSlideChange={handleSlideChange}
                spaceBetween={30}
                breakpoints={{
                    240: {
                        slidesPerView: 1,
                    },
                    565: {
                        slidesPerView: 2,
                    },
                    995: {
                        slidesPerView: 3,
                    },
                }}
                className=" card-slider-4-columns !py-5"
            >
                {currency.map((item) => {
                    const { id } = item;
                    return (
                        <SwiperSlide key={id}>


                            <div className="min-h-[300px] border dark:border-gray-200 rounded-xl py-8 px-6 group hover:-translate-y-2 transition-all duration-300">
                                <div className="flex justify-start gap-2 text-center mb-8">
                                    <div className={`group-hover:bg-gradient-to-tr group-hover:from-hotPink group-hover:via-purplish group-hover:to-accent transition-all  bg-jacarta-800 dark:bg-jacarta-900 duration-200 rounded-md  h-[70px] w-[70px]
                                   ${(item.img === 'matic' || item.img === 'ripple') && 'dark:bg-[#ffffff16] bg-jacarta-800/90 p-2'}`}>
                                        <Image width={70} height={70}
                                            src={`/images/currency/${item.img}.svg`}
                                            alt={item.alt}
                                            className="rounded-md "
                                        />
                                    </div>
                                    <div className="text-start">
                                        <h5 className="font-normal text-2xl mb-2">{item.name}</h5>
                                        <p className="font-extralight dark:text-jacarta-400 text-lg">Cryptocurrency</p>
                                    </div>

                                </div>
                                <hr className="text-gray-200 mb-8" />
                                <p className=" dark:text-jacarta-400 text-[18px]">
                                    {item.text}
                                </p>
                            </div>


                        </SwiperSlide>
                    );
                })}
            </Swiper>



        </Fade >
        <Fade ssrReveal delay={300}>
            <div className="flex md:hidden justify-center lg:justify-end items-end gap-6 mt-4">
                <div onClick={handlePrev} className={!isAtBeginning ? "p-4 rounded-md bg-gradient-to-tr from-hotPink via-purplish to-accent  button cursor-pointer  z-[2] transition-all duration-500 hover:-translate-y-1  disabled:bg-white text-white"
                    :
                    "dark:bg-jacarta-800 rounded-md text-white p-4 opacity-50 cursor-not-allowed z-[2] transition-all duration-500 hover:-translate-y-1  disabled:bg-white "}

                    disabled={isAtBeginning}
                >
                    <MdArrowBack className={!isAtBeginning ? "w-[30px] h-[30px] text-white" : "w-[30px] h-[30px] dark:text-white text-jacarta-800"} />
                </div>
                <div onClick={handleNext} className={!isAtEnd ? "p-4 rounded-md bg-gradient-to-tr from-hotPink via-purplish to-accent  button cursor-pointer z-[2] transition-all duration-500 hover:-translate-y-1  disabled:bg-white text-white"
                    :
                    "dark:bg-jacarta-800 text-white p-4 opacity-50 rounded-md cursor-not-allowed  z-[2] transition-all duration-500 hover:-translate-y-1  disabled:bg-white "}

                    disabled={isAtEnd}
                >
                    <MdArrowForward className={!isAtEnd ? "w-[30px] h-[30px] text-white" : "w-[30px] h-[30px] dark:text-white text-jacarta-800"} />
                </div>
            </div>
        </Fade>
        {/* </Fade > */}
    </>;
};

export default Carousal;
