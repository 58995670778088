import React from "react";
import {
  Hero,
} from "../../components/component";
import Faq from "./faq";
import Invest from "./invest";
import Support from "./support";
import EcoSystem from "./ecoSystem";
import HowItWorks from "./howItWorks";
import Premium from "./premium";
import ContactUs from "./contactUs";
import PreferToSay from "../../components/PreferToSay";
import Questions from "./questions";
import MajorFeatures from "../../components/features/majorFeatures";



const Home_1 = () => {
  return (
    <main>
      <Hero />
      <EcoSystem />
      <HowItWorks />
      <MajorFeatures />
      <Premium />
      <Faq />
      {/* <TrustedPartner /> */}

      <Support />
      <Invest />

      {/* <Reviews />
      <Blogs /> */}
      <ContactUs />
      <PreferToSay />
      <Questions />

    </main>
  );
};

export default Home_1;
