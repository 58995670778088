import Image from "next/image";

import Fade from 'react-reveal/Fade';
import SearchBar from "./Searchbar";
import TLD from "./TLD";
import VideoIntro from "./VideoIntro";

const hero = () => {

  return (
    <section className="border border-x-0 border-t-0 dark:border-gray-200 pb-[60px]" >
      <div className="relative container pt-[68px] px-[20px] md:pt-[98px] md:px-[24px] lg:pt-[118px] lg:px-0 ">
        <div className="relatve">
          <div className=" h-full mx-auto max-w-[895px] relative">
            <div className="h-full items-center gap-4 md:grid-cols-12">
              <div className="lg:px-6 h-full text-center pt-10 md:items-start  md:pt-20 xl:col-span-4">
                <Fade ssrReveal bottom>
                  <h1 className="text-jacarta-900 font-light font-display mt-[20px] mb-4 text-center text-[30px] md:text-[44px] dark:text-white  lg:text-[64px] xl:text-[68px] max-w-[500px] md:max-w-[600px] lg:max-w-[850px] mx-auto leading-tight	">
                    Own Your<br /><span className="animate-gradient"> Top-Level Domain</span>
                  </h1>
                </Fade>
                <Fade ssrReveal bottom delay={350}>
                  <p className="mb-[28px] text-center text-[16px] md:text-[18px] font-normal max-w-[500px] md:max-w-[450px] lg:max-w-[450px] mx-auto">
                    {/* Navigate the future of the internet - Search your blockchain identity today. */}
                    Preparing to Launch - Preview a few of our platforms features shaping the future of the internet.
                  </p>
                </Fade>

                <SearchBar main={true} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <TLD />

      <div className="relative container pb-[68px] px-[20px] md:pb-[98px] md:px-[24px] lg:pb-[118px] lg:px-0 ">

        <Fade ssrReveal bottom delay={350} >
          <div className="flex flex-col md:flex-row gap-6 md:gap-8 justify-center pt-6">
            <a href="#contact" className="items-center  justify-center rounded-md text-[18px] font-medium  bg-gradient-to-tr from-hotPink via-purplish to-accent py-[22px] px-[32px] md:py-[24px] md:px-[38px] cursor-pointer button z-[2] transition-all duration-500 text-white hover:-translate-y-1 text-center"

            >Start Now</a>
            <a href="#contact" className="items-center hidden lg:flex justify-center rounded-md text-[18px] font-medium dark:bg-jacarta-800  hover:bg-jacarta-base cursor-pointer  py-[22px] px-[32px] md:py-[24px] md:px-[38px] z-[2] transition-all duration-500 hover:-translate-y-1 bg-gray-300 text-white dark:hover:bg-white dark:hover:text-gray-300"

            >Learn more</a>
          </div>
        </Fade>
      </div>

      <VideoIntro />

      <div className={`h-[400px] md:h-[700px] lg:h-[800px] relative -mt-[70%] md:-mt-[50%] lg:-mt-[20%] z-[-1] top-0 dark:mix-blend-lighten mix-blend-difference`} >
        <div className="relative w-full h-full z-[-1] animate-fade">
          <Image
            fill
            sizes="100vw"
            src="/images/hero/hero-space.png"
            alt="hero-background"
            className=" z-[-1] object-cover"
          />
        </div>

      </div>

    </section>
  );
};

export default hero;
