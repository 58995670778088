import Eco from "./Eco";
import Fade from 'react-reveal/Fade';

const EcoSystem = () => {
    return (
        <Fade ssrReveal>
            <div className="overflow-hidden  container  px-[20px]  md:px-[24px]  lg:px-0 dark:bg-jacarta-900 relative -mt-[150px] md:-mt-[250px] lg:-mt-[200px] mb-[20px] md:mb-[50px] lg:mb-[40px] ">

            
                <div className="hidden dark:block absolute right-0 top-0 bottom-0 left-auto z-[10] w-[30%] 
"
                    style={{
                        backgroundImage: 'linear-gradient(270deg, #07091b, rgba(7, 9, 27, 0))',
                    }}
                ></div>
                <div className="hidden dark:block absolute right-auto top-0 bottom-0 left-0 z-[10] w-[30%]" style={{
                    backgroundImage: 'linear-gradient(90deg, #07091b, rgba(7, 9, 27, 0))',
                }}></div>
                <div className=" animate-marquee space-x-8 flex relative">

                    <Eco />
                </div>


            </div>
        </Fade>
    );
};
export default EcoSystem;


        // bg-gradient-to-tr from-[#07091b] via-transparent to-[#00000000"]
