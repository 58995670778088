import Image from "next/image";
import React from "react";
import FaqAccordion from "./accoedion";
import { Fade } from "react-reveal";
import Link from "next/link";
const Faq = () => {
  const [faq, setFaq] = React.useState(1);
  return (
    <div>
      <div className="container py-[68px] px-[20px] md:py-[98px] md:px-[24px] lg:py-[118px] lg:px-0   pt-24
       dark:bg-jacarta-900  border border-x-0 border-t-0 dark:border-gray-200  
       
       pb-[60px]">

        {/* <Fade ssrReveal >
          <div className="flex flex-col lg:flex-row gap-10 lg:justify-between">
            <div className="md:max-w-[500px] mx-auto lg:mx-0 text-center lg:text-start">
              <h2 className="text-[24px] md:text-[32px] lg:text-[38px]  font-medium  mb-4 text-center lg:text-start">Giveaway!</h2>
              <p className="text-[16px] md:text-[18px] dark:text-jacarta-400">
                Our giveaway is just around the corner! Click below to learn more about the upcoming event.
              </p>
            </div>

            <div className="flex flex-col md:flex-row justify-center lg:justify-end lg:items-start gap-6 w-full text-center">
              <Link href="#contact" className="items-center justify-center rounded-md text-[18px] font-medium bg-gradient-to-tr from-hotPink via-purplish to-accent py-[22px] px-[32px] md:py-[24px] md:px-[38px] cursor-pointer button  z-[2] transition-all duration-500 text-white hover:-translate-y-1"

              >Learn more</Link>
            </div>
          </div>
        </Fade> */}
        <div className="justify-between grid lg:grid-cols-2 md:gap-10 mt-[10px] md:mt-[50px] max-w-[500px] mx-auto lg:max-w-full lg:mx-0">
          <div className="md:mr-[40px] my-[10px] md:my-[40px]">
            <Fade ssrReveal delay={400}>

              <FaqAccordion setFaq={setFaq} />

            </Fade>
          </div>
          <div>
            <Fade ssrReveal >
              {faq === 1 ?
                <Image width={427} height={100}
                  key={faq}
                  src="/images/faq/createAccount.svg"
                  alt="search-domain"
                  className="px-[40px] lg:px-0 mx-auto  lg:mr-0  fade-in" />
                : faq === 2 ?
                  < Image width={427} height={200}
                    key={faq}
                    src="/images/faq/currency.svg"
                    alt="buy-and-own"
                    className="px-[40px] md:px-0 mx-auto  md:mr-0 fade-in" />
                  :
                  <Image width={427} height={200}
                    key={faq}
                    src="/images/faq/earn.svg"
                    alt="mint-and-transfer"
                    className="px-[40px] md:px-0 mx-auto  md:mr-0 fade-in" />
              }
            </Fade>
          </div>
        </div>

      </div>

    </div >


  );
};

export default Faq;
