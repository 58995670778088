import React from "react";
import { Fade } from "react-reveal";
import { FaFacebookF, FaDiscord, FaInstagram, FaLinkedinIn } from "react-icons/fa"
import Link from "next/link";
import Image from "next/image";
import { useFormspark } from "@formspark/use-formspark";
import { useRef } from "react";
import Loading from "../../../components/Loading";
import { toast } from "react-hot-toast";

const ContactUs = () => {
    const formRef = useRef();
    const FORMSPARK_FORM_ID = "geYk2srU";
    const [submit, submitting] = useFormspark({
        formId: FORMSPARK_FORM_ID,
    });

    const onSubmit = async (e) => {
        e.preventDefault();
        const formData = {};
        const formElements = formRef.current.elements;

        for (let i = 0; i < formElements.length; i++) {
            const element = formElements[i];
            if (element.name) {
                formData[element.name] = element.value;
            }
        }
        await submit({ ...formData });
        toast.success("Thanks for contacting us!")
        formRef.current.reset()

    };
    return (
        <>
            <div id="contact" className="container py-[68px] px-[20px] md:py-[98px] md:px-[24px] lg:py-[118px] lg:px-0  border border-x-0 border-t-0 dark:border-gray-200">
                <Fade ssrReveal delay={400}>
                    <div className="grid lg:grid-cols-3 gap-10">
                        <div className="col-span-1">
                            <h1 className="text-[30px] md:text-[44px] lg:text-[64px] text-center lg:text-start font-medium mb-[16px] leading-tight">Get in <br className="hidden lg:flex" />touch <br className="hidden md:flex" /> with us</h1>
                            <p className="text-[16px] md:text-[18px] mb-[40px] text-center lg:text-start w-[50%] lg:w-full mx-auto">
                                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit sit vitae donec aliquam. */}
                                Join our lively social community for engaging connections and enriching content
                            </p>
                            <div className="flex justify-center lg:justify-start gap-6 mb-[40px]">

                                <a target="_blank" href="https://www.facebook.com/NetZyloInc/" >
                                    <FaFacebookF className="w-[39px] h-[39px] p-2 rounded-lg text-white bg-jacarta-800 hover:bg-white hover:text-gray-300 transition-all duration-500  hover:-translate-y-1" />
                                </a>
                                <a target="_blank" href="https://twitter.com/NetZylo/">
                                    <svg viewBox="0 0 24 24" width="24" height="24" aria-hidden="true" className="w-[39px] h-[39px] p-2 rounded-lg text-white bg-jacarta-800 hover:bg-white hover:fill-gray-300 transition-all  duration-500  hover:-translate-y-1 fill-white"><g><path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path></g></svg>

                                </a>
                                <a target="_blank" href="https://instagram.com/netzylo/">
                                    <FaInstagram className="w-[39px] h-[39px] p-2 rounded-lg text-white bg-jacarta-800 hover:bg-white hover:text-gray-300 transition-all  duration-500  hover:-translate-y-1" />
                                </a>
                                <a target="_blank" href="https://www.linkedin.com/company/netzylo/">
                                    <FaLinkedinIn className="w-[39px] h-[39px] p-2 rounded-lg text-white bg-jacarta-800 hover:bg-white hover:text-gray-300 transition-all  duration-500  hover:-translate-y-1" />
                                </a>
                              

                            </div>
                            <a target="_blank" href="https://discord.com/channels/netzylo/" className="flex items-center justify-center lg:justify-start md:w-[50%] mx-auto lg:w-full gap-4 border dark:border-gray-200 border-gray-900 py-8 px-10 cursor-pointer hover:opacity-70 transition-all duration-500 hover:-translate-y-1 rounded-[12px]">
                                <div className="bg-gradient-to-tr from-hotPink via-purplish to-accent bg-jacarta-900  flex items-center justify-center rounded-full h-[3rem] w-[3rem]">
                                    <svg className="icon inline-block rounded-full fill-white h-[1.7rem] w-[1.7rem] ">
                                        <use xlinkHref={`/icons.svg#icon-discord`}></use>
                                    </svg>
                                </div>
                                <p className="text-[18px] ">Join our community</p>
                            </a>

                        </div>
                        <form ref={formRef} onSubmit={onSubmit} className="lg:col-span-2 rounded-[12px] gradient-box bg-white dark:bg-jacarta-900 px-[30px] py-[74px]">
                            <div className="flex flex-col md:flex-row gap-4">
                                <div className="mb-6 lg:w-1/2">
                                    <label className="font-display text-[18px] text-jacarta-900 mb-[18px] block dark:text-white ">
                                        Name
                                    </label>
                                    <input
                                        name="name"
                                        className="contact-form-input dark:bg-jacarta-900  hover:ring-accent/10 focus:ring-accent border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-[26px] px-[24px] hover:ring-2 dark:text-white"
                                        id="name"
                                        type="text"
                                        placeholder="Your Name"
                                        required
                                    />
                                </div>
                                <div className="mb-6 lg:w-1/2">
                                    <label className="font-display text-[18px] text-jacarta-900 mb-[18px] block dark:text-white">
                                        Email
                                    </label>
                                    <input
                                        name="email"
                                        className="contact-form-input dark:bg-jacarta-900  hover:ring-accent/10 focus:ring-accent border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-[26px] px-[24px] hover:ring-2 dark:text-white"
                                        id="email"
                                        type="email"
                                        placeholder="example@youremail.com"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col md:flex-row  gap-4">
                                <div className="mb-6 lg:w-1/2">
                                    <label className="font-display text-[18px] text-jacarta-900 mb-[18px] block dark:text-white">
                                        Phone number
                                    </label>
                                    <input
                                        name="phone"
                                        className="contact-form-input dark:bg-jacarta-900  hover:ring-accent/10 focus:ring-accent border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-[26px] px-[24px] hover:ring-2 dark:text-white"
                                        id="phone"
                                        type="number"
                                        placeholder="(000) 000 - 0000"
                                        required
                                    />
                                </div>
                                <div className="mb-6 lg:w-1/2">
                                    <label className="font-display text-[18px] text-jacarta-900 mb-[18px] block dark:text-white">
                                        Subject
                                    </label>
                                    <input
                                        name="subject"
                                        className="contact-form-input dark:bg-jacarta-900  hover:ring-accent/10 focus:ring-accent border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-[26px] px-[24px] hover:ring-2 dark:text-white"
                                        id="subject"
                                        type="text"
                                        placeholder="Ex. Web3 Domains"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="mb-4">
                                <label className="font-display text-[18px] text-jacarta-900 mb-[18px] block dark:text-white">
                                    Message
                                </label>
                                <textarea
                                    id="message"
                                    className="contact-form-input dark:bg-jacarta-900  hover:ring-accent/10 focus:ring-accent border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-[26px] px-[24px] hover:ring-2 dark:text-white"
                                    required
                                    name="message"
                                    rows="5"
                                    placeholder="Please type your message..."
                                ></textarea>
                            </div>
                            <div className="mt-14">
                                <button type="submit" className={`text-center justify-center rounded-md text-[18px] font-medium  py-[22px] px-[32px] md:py-[24px] md:px-[38px] cursor-pointer button z-[2] transition-all duration-500 text-white hover:-translate-y-1 w-full block md:inline  ${!submitting ? " bg-gradient-to-tr from-hotPink via-purplish to-accent" : " disabled:text-transparent disabled:bg-jacarta-800"}  relative `}
                                    disabled={submitting}
                                >
                                    {submitting ? <Loading size={5} /> : "Send message"}</button>
                            </div>
                        </form>


                    </div>
                </Fade>
            </div>
        </>
    )
}


export default ContactUs