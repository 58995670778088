

import React, { useEffect, useRef, useState } from 'react';
import { FaPlayCircle } from 'react-icons/fa';




const VideoIntro = () => {
	const [isPlaying, setIsPlaying] = useState(false);
	const videoRef = useRef(null);


	const handlePlayPause = () => {
		if (videoRef.current.paused) {
			videoRef.current.play();
			setIsPlaying(true);
		} else {
			videoRef.current.pause();
			setIsPlaying(false);
		}
	};


	return (
		<div className="relative container pb-[68px] px-[20px] md:pb-[98px] md:px-[24px] lg:pb-[118px] lg:px-0 ">
			<div className='gradient-box'>
				<div className=" relative aspect-w-16 aspect-h-9 h-[350px] md:h-[450px] lg:h-[600px] overflow-hidden bg-jacarta-900">

					<video
						ref={videoRef}
						loop

						onClick={handlePlayPause}
						className="absolute w-full h-full object-cover"
					>
						<source src="/videos/intro-video.mp4" type="video/mp4" />
						Your browser does not support the video tag.
					</video>
					{!isPlaying && (
						<button
							onClick={handlePlayPause}
							className="absolute inset-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center"
						>
							<span className=" bg-jacarta-900 rounded-full">
								<FaPlayCircle className='text-white h-16 w-16' />
							</span>
						</button>
					)}

				</div>
			</div>
		</div>
	);
};

export default VideoIntro;