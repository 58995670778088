import React from "react";
import Image from "next/image";
import { useTheme } from "next-themes";
// import MajorFeatures from "../../components/features/majorFeatures";
import Fade from 'react-reveal/Fade';
import Link from "next/link";

const HowItWorks = () => {
    const { theme } = useTheme();

    return (
        <section>
            <div className="container relative py-[68px] px-[20px] md:py-[98px] md:px-[24px] lg:py-[118px] lg:px-0  border border-x-0 border-t-0 dark:border-gray-200   ">
                <div className="mt-20 max-w-[600px] lg:max-w-full mx-auto  rounded-[12px] bg-white dark:bg-jacarta-900 gradient-box relative">
                    <div className="grid lg:grid-cols-2 lg:gap-10">
                        <Fade bottom delay={350}>


                            <div className="px-[24px] z-[10] md:px-[60px] lg:pr-[40px] py-[60px] md:py-[80px] lg:py-[128px] lg:pl-[72px]">

                                <h3 className="text-[28px] font-bold mb-4 text-jacarta-900 dark:text-white ">How It Works

                                </h3>

                                <p className="text-[18px] mb-16 lg:mb-10 dark:text-white text-jacarta-900">Web3 domains provide a digital presence by enabling individuals and businesses to secure unique, blockchain-based identities on the decentralized web. </p>

                                <a href="#contact" className=" items-center justify-center rounded-md text-[18px] font-medium dark:bg-jacarta-800 hover:bg-jacarta-base  cursor-pointer py-[22px] px-[32px] md:py-[24px] md:px-[38px]  z-[100] transition-all duration-500 text-white hover:-translate-y-1 bg-gray-300 inline-block dark:hover:bg-white dark:hover:text-gray-300 "

                                >Get started</a>

                            </div>
                            <div className=" px-[40px] lg:pt-[30px] dark:mix-blend-lighten z-[5]  overflow-hidden"

                            >

                                <Image
                                    width={317} height={200}
                                    src="/images/wallets/wallet.svg"
                                    alt="wallet"
                                    className="mx-auto -mb-[170px] object-cover"
                                   
                                />

                            </div>
                        </Fade>
                    </div>


                </div>


                <div className={` w-full absolute md:left-20 lg:left-0 bottom-0 md:bottom-10 lg:bottom-28 z-[1]  dark:mix-blend-lighten mix-blend-difference`} >
                    <div className="relative  h-[300px] w-[600px] lg:w-[1200px] animate-fade  ">
                        <Image
                            fill
                            sizes="100vw"
                            src="/images/hero2.webp"
                            alt="wallet-background"
                            className="object-cover "
                        />
                    </div>
                </div>
            </div>

        </section>
    );
};

export default HowItWorks;